
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Options extends Vue {
  get range(): number[] {
    return [this.$store.state.minPizzaSize, this.$store.state.maxPizzaSize]
  }
  set range(value: number[]) {
    this.$store.commit("updateMinPizzaSize", value[0]);
    this.$store.commit("updateMaxPizzaSize", value[1]);
  }
}
