import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    minPizzaSize: 9,
    maxPizzaSize: 16,
    maxPeopleCount: 10,
    peopleCount: 4,
  },
  mutations: {
    updatePeopleCount(state, value) {
      state.peopleCount = value;
    },
    updateMinPizzaSize(state, value) {
      state.minPizzaSize = value;
    },
    updateMaxPizzaSize(state, value) {
      state.maxPizzaSize = value;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [new VuexPersistence().plugin]
})
