
import { Component, Vue } from "vue-property-decorator";

interface PizzaRequired {
  pizzaCount: number;
  pizzaSize: number;
}

@Component
export default class PizzaNeeded extends Vue {
  pizzaSize = 12;

  get maxPizzaCount(): number {
    return this.peopleCount * 2;
  }

  get peopleCount(): number {
    return this.$store.state.peopleCount;
  }
  set peopleCount(value: number) {
    this.$store.commit("updatePeopleCount", value);
  }

  get pizzaResultsToShow(): PizzaRequired[] {
    return this.pizzaResults.filter(
      (x) =>
        this.$options.filters?.nearestHalf(x.pizzaSize) >= this.$store.state.minPizzaSize &&
        this.$options.filters?.nearestHalf(x.pizzaSize) <= this.$store.state.maxPizzaSize
    );
  }

  get pizzaResults(): PizzaRequired[] {
    var results = [];
    for (let pizzas = 1; pizzas <= this.maxPizzaCount; pizzas++) {
      results.push({
        pizzaCount: pizzas,
        pizzaSize: this.pizzaSizeRequiredForPeople(pizzas),
      });
    }
    return results;
  }

  private pizzaSizeRequiredForPeople(numberOfPeople: number): number {
    return Math.sqrt((this.peopleCount / numberOfPeople) * this.pizzaSize ** 2);
  }
}
