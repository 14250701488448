
import Vue from "vue";
import HowMuchPizzaEach from "./components/HowMuchPizzaEach.vue";
import PizzaNeeded from "./components/PizzaNeeded.vue";
import Options from "./components/Options.vue";

export default Vue.extend({
  name: "App",

  components: {
    HowMuchPizzaEach,
    PizzaNeeded,
    Options,
  },
  computed: {
    appBarHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 10;
      }
      return "50";
    },
  },

  data: () => ({
    tab: "tab1",
  }),
});
